/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

function Seo({ description, keywords, title, image, pathname, children, language }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
          }
        }
      }
    `
  );


  const baseUrl = site.siteMetadata.siteUrl + '/' + pathname;

  const defaultKeyWords = 'viennaeventtickets, vienna, ticket, opera, vienna event ticket, online booking';


  const defaultDescription = `Vienna – The Capital of Classical Music and the Waltz
  Welcome to Vienna, the city of great composers like Mozart, Beethoven, and Strauss! Immerse yourself in the world of classical music and secure your Vienna concert tickets to enjoy unforgettable experiences at the city’s most renowned venues.
  Experience the elegance of Strauss concerts and the magic of the Viennese Waltz at stunning locations such as the Kursalon Vienna or the Golden Hall of the Musikverein. Be enchanted by the festive sounds of the Vienna Philharmonic, especially at the iconic New Year’s Concerts.
  Our offerings include:
  Vivaldi concerts in Vienna – a Baroque delight in churches and palaces.
  Mozart concerts in the Golden Hall – a must-see for every music enthusiast.
  Beethoven concerts and romantic symphonies at the Brahms Hall.
  Atmospheric Christmas and Advent concerts in historic venues like St. Stephen’s Cathedral.
  Don’t miss exclusive events like the Vienna Philharmonic Ball or performances at the majestic Schönbrunn Palace. We also offer last-minute opera tickets at the Vienna State Opera, affordable standing room tickets, and magical candlelight concerts.
  Strauss and the Viennese Waltz
  Strauss and his works take center stage in many performances. Enjoy globally celebrated pieces like the "Blue Danube Waltz" and "The Fledermaus" in breathtaking settings.
  Discover Vienna as the world’s music capital and book your concert tickets today!`;

  const defaultDescriptionDE = `Wien – Die Hauptstadt der klassischen Musik und des Wiener Walzers
  Willkommen in Wien, der Heimat der großen Komponisten wie Mozart, Beethoven und Strauß! Tauchen Sie ein in die Welt der klassischen Musik und sichern Sie sich Ihre Tickets für klassische Konzerte in Wien, um unvergessliche Erlebnisse in den besten Veranstaltungsorten zu genießen.
  Erleben Sie die Magie der Strauß-Konzerte und die Eleganz des Wiener Walzers in prunkvollen Sälen wie dem Kursalon Wien oder der Goldenen Halle des Musikvereins. Lassen Sie sich von den festlichen Klängen der Wiener Philharmoniker verzaubern, insbesondere bei den ikonischen Neujahrskonzerten.
  Unsere Angebote umfassen:
  Vivaldi-Konzerte in Wien – ein Barockgenuss in Kirchen und Palästen.
  Mozart-Konzerte in der Goldenen Halle – ein Muss für jeden Musikliebhaber.
  Beethoven-Konzerte und romantische Sinfonien im Brahms-Saal.
  Stimmungsvolle Weihnachts- und Adventskonzerte in historischen Kirchen wie dem Stephansdom.
  Verpassen Sie nicht die Gelegenheit, exklusive Events wie den Wiener Philharmoniker Ball oder Aufführungen im prächtigen Schloss Schönbrunn zu erleben. Auch für Last-Minute-Operntickets in der Wiener Staatsoper, günstige Stehplätze, oder Candlelight-Konzerte finden Sie bei uns das richtige Angebot.
  Strauß und der Wiener Walzer stehen im Mittelpunkt vieler Aufführungen. Genießen Sie die weltbekannten Werke wie den „Donauwalzer“ und „Die Fledermaus“ in prachtvoller Kulisse.
  Erleben Sie Wien als Hauptstadt der Musik und buchen Sie noch heute Ihre Konzerttickets!`;

  const defaultDescriptionAppendix = language === 'de' ? defaultDescriptionDE : defaultDescription;

  const seo = {
    title: title || site.siteMetadata.title,
    description: description + '' + defaultDescriptionAppendix || site.siteMetadata.description,
    image: image || site.siteMetadata.image,
    url: baseUrl,
    keywords: keywords !== null ? keywords : defaultKeyWords
  };


  return (
    <>
      <title>{seo.title}</title>
      <meta name="theme-color" content="#e6594d" />
      <meta name="type" content="website" />
      <meta name="og:type" content="website" />
      <meta name="title" content={seo.title} />
      <meta name="og:title" content={seo.title} />
      <meta name="description" content={seo.description} />
      <meta name="og:description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="og:image" content={seo.image} />
      <meta name="keywords" content={seo.keywords} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="fb:app_id" content="5816822891712798" />
      {children}
    </>
  );
}

Seo.defaultProps = {
  title: '',
  keywords: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default Seo;
